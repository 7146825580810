<template>
  <v-container>
    <CourtTypeList/>
  </v-container>
</template>

<script>
import CourtTypeList from "@/components/app_setting/court_type/CourtTypeList";

export default {
  name: "CourtTypePage",
  components: {
    CourtTypeList,
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Court Types'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>